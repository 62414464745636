// 角色授权
<template>
  <div class="content">
    <backTitle lable="角色管理" style="padding-bottom: 0;"></backTitle>
    <base-from
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="fromdata"
    >
    </base-from>
    <div class="main-page-table__header flex-start">授权角色：{{ row.roleName }}</div>
    <base-table
      class="main-page-table with-add"
      :columns="columns"
      :showPage="true"
      :api="roleApi"
      getApi="getresourceByPage"
      :loadCount="loadCount"
      :queryParas="queryParas"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      ref="tableData"
      :dataSource.sync="tableData"
      :webPage="false"
      @pageIndexChange="pageIndexChange"
      @pageSizeChange="pageSizeChange"
    >
      <template slot="authStatus" slot-scope="{ row }">
        <el-switch
          v-model="row.authStatus"
          :active-color="'#13CE66'"
          :inactive-color="'#D2CDC8'"
          active-value="0"
          inactive-value="1"
          @change="handleAuthChange(row)"
        >
        </el-switch>
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          @click="handleAuth(scope.row)"
          content="授权"
          icon="iconfont iconshouquan"
        ></icon-button>
      </template>
    </base-table>
    <base-dialog
      :visible.sync="visible111"
      width="1200px"
      :showFooter="false"
      title="权限管理"
      class="dialog"
    >
      <div class="tree">
        <div class="row">
          <div>授权角色：{{ row.roleName }}</div>
          <div>授权应用：{{ editdata.resourceName }}</div>
        </div>
        <div class="row">
          <div class="label">关键字</div>
          <el-input
            placeholder="请输入搜索内容"
            v-model="filterText"
          ></el-input>
        </div>
      </div>
      <el-container style="width: 100%; margin-top: -10px" v-if="visible">
        <!-- 菜单操作栏 -->
        <el-aside
          style="width: 60%; border-right: 1px solid #e6e6e6; overflow-x: auto"
        >
          <el-tree
            class="filter-tree"
            :data="data"
            node-key="id"
            :props="defaultProps"
            :filter-node-method="filterNode"
            :default-expanded-keys="['1']"
            :default-checked-keys="checkedkeys"
            ref="tree"
            show-checkbox
          >
          </el-tree>
        </el-aside>
        <!-- 按钮操作区 -->
        <transition name="el-fade-in-linear">
          <el-main v-if="permsTitle !== ''" class="authRight">
            {{ `「 ${permsTitle}」操作权限` }}
            <el-tree
              :data="operationSource"
              show-checkbox
              default-expand-all
              node-key="resourceKid"
              ref="rightTree"
              highlight-current
              @check-change="operationChange"
              @check="operationCheck"
              :default-checked-keys="operationCheckedList"
              :props="defaultProps"
            >
              <span class="custom-tree-node" slot-scope="{ data }">
                <el-tooltip
                  :enterable="false"
                  :content="data.resourceDesc"
                  placement="top"
                  v-if="data.resourceDesc"
                >
                  <span>{{ data.resourceName }}</span>
                </el-tooltip>
                <span v-else>{{ data.resourceName }}</span>
                <!-- <el-tooltip :content="data.resourceDesc" placement="top">
                  <span>{{data.resourceName}}</span>
                </el-tooltip> -->
              </span>
            </el-tree>
          </el-main>
        </transition>
      </el-container>

      <template slot="footer">
        <base-button label="保 存" @click="submit"></base-button>
        <base-button
          label="关 闭"
          type="default"
          @click="visible = false"
        ></base-button>
      </template>
    </base-dialog>
    <role-auth :visible.sync="visible" :form="editdata"></role-auth>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import * as roleApi from '@/api/sys/role'
import BaseFrom from '@/components/common/base-form//base-form.vue'
import { dataForm, dataTable } from './utils/jurisdiction.config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import roleAuth from './role-management-auth.vue'
import backTitle from '@/pages/business/components/backtitle.vue'

export default {
  components: {
    BaseFrom,
    BaseTable,
    BaseDialog,
    BaseButton,
    IconButton,
    roleAuth,
    backTitle
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },
  data () {
    return {
      nocheckedkeys: [],
      checkedkeys: [], // 默认选中的值
      editdata: {},
      visible111: false,
      row: {},
      loadCount: 0,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      operationCheckedList: [],
      operationSource: [],
      permsTitle: '',
      queryParas: {
        pageSize: 10,
        pageNumber: 1,
        resourceType: 'APP',
        delStatus: '0',
        roleId: ''
      },
      tableData: [],
      visible: false,
      filterText: '',
      data: [
        {
          id: '1',
          label: '菜单权限',
          children: []
        }
      ]
    }
  },
  mounted () {
    this.row = this.$route.query
    this.queryParas.roleId = this.row.roleId
    this.handleFilter()
  },
  methods: {
    operationChange: function () {

    },
    operationCheck () {},
    // 按resourceKid 取出相应对象
    getObjec (data, resourceKid) {
      let obj = {}
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        if (item.resourceKid === resourceKid) {
          obj = JSON.parse(JSON.stringify(item))
          return obj
        } else {
          if (item.children && item.children.length > 0) {
            obj = this.getObjec(item.children, resourceKid)
            if (obj) {
              return obj
            }
          }
        }
      }
    },
    // 取出两数组不相等的值
    getArrDifference (arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v)
      })
    },

    submit () {
      // 获取用户选中的节点id
      // 与接口返回已选中节点进行比对  如果选中与接口返回节点一致 则直接关闭弹窗 不调用接口
      // 不一致时 拿到不一致的节点id  如果 不一致的id 在返回节点中 则为将选中节点改为未选中 将 authStatus==1
      //  如果 不一致的id 在用户选中节点中 则为将未选中节点改为选中 将 authStatus==0
      var chosearr = this.$refs.tree.getCheckedNodes()
      var choseid = []
      if (chosearr) {
        chosearr.forEach((item) => {
          choseid.push(item.id)
        })
      }
      this.checkedkeys.sort(function (m, n) {
        if (m < n) return -1
        else if (m > n) return 1
        else return 0
      })
      choseid.sort(function (m, n) {
        if (m < n) return -1
        else if (m > n) return 1
        else return 0
      })
      const data = []
      var obj = {}
      if (choseid.toString() === this.checkedkeys.toString()) {
        // 数据未修改 则直接关闭弹窗
        this.visible = false
      } else {
        const modifyarr = this.getArrDifference(choseid, this.checkedkeys) // 拿出修改的id
        var index = modifyarr.indexOf('1')
        if (index > -1) {
          modifyarr.splice(index, 1)
        }
        modifyarr.forEach((item) => {
          if (this.checkedkeys.indexOf(item) !== -1) {
            obj = this.getObjec(this.data[0].children, item)
            if (obj) {
              obj.authStatus = 1
              data.push(obj)
            }
          }
          if (choseid.indexOf(item) !== -1) {
            obj = this.getObjec(this.data[0].children, item)
            if (obj) {
              obj.authStatus = 0
              data.push(obj)
            }
          }
        })
      }
      // if (data) {
      //   console.log(data)
      //   return false
      // }
      // roleApi.batch(data).then((res) => {
      //   if (res.data) {
      //     this.success('授权成功')
      //     this.visible = false
      //   }
      // })
    },
    // 构造树形数据
    structure (dataarr = []) {
      if (dataarr) {
        dataarr.forEach((item, index, arr) => {
          arr[index].label = item.path + ' ' + item.resourceName
          arr[index].id = item.resourceKid
          if (item.authStatus === '0') {
            this.checkedkeys.push(item.resourceKid)
          }
          if (item.authStatus === '1') {
            this.nocheckedkeys.push(item.resourceKid)
          }
          if (item.children && item.children.length !== 0) {
            this.structure(item.children)
          }
        })
      }
      this.checkedkeys = JSON.parse(JSON.stringify(this.checkedkeys))
      return dataarr
    },

    async handleAuthChange (row) {
      // 0 -> 1 关闭
      // 1 -> 0 打开
      const { authStatus } = row
      try {
        // 取消授权 做用户认证
        if (authStatus === '1') {
          await this.$confirm('是否取消该应用的授权?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false,
            closeOnPressEscape: false
          })
        }
        // 后台修改数据
        await roleApi.roleAuth(row)

        // 消息提示
        this.success(authStatus === '1' ? '取消授权成功' : '授权成功')
      } catch (error) {
        // 授权失败或取消时 状态回退
        row.authStatus = authStatus === '1' ? '0' : '1'
      }
    },
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    handleFilter () {
      if (!this.queryParas.roleId) {
        this.queryParas.roleId = this.row.roleId
      }
      this.queryParas.pageNumber = 1
      this.loadCount++
    },
    clearParams () {
      this.queryParas = this.$options.data().queryParas
    },
    handleAuth (row) {
      if (row.authStatus === '1') {
        return this.warning('该应用未授权')
      }
      this.visible = true
      this.editdata = { ...row, roleName: this.row.roleName }
      // this.data[0].children = []
      // const params = {
      //   parentId: row.resourceKid,
      //   resourceType: 'MENU',
      //   roleId: this.row.roleId ? this.row.roleId : '2109081502044040001'
      // }
      // roleApi.getTreeresource(params).then((res) => {
      //   this.data[0].children = this.structure(res.data)
      //   this.$nextTick(() => {
      //     this.checkedkeys.forEach((elem) => {
      //       this.$refs.tree.setChecked(elem, true)
      //     })
      //     this.nocheckedkeys.forEach((elem) => {
      //       this.$refs.tree.setChecked(elem, false)
      //     })
      //   })
      // })
    },
    pageIndexChange () {},
    pageSizeChange () {}
  },
  computed: {
    roleApi () {
      return roleApi
    },
    columns () {
      return dataTable(this)
    },
    from () {
      return dataForm(this)
    }
  }
}
</script>
<style scoped lang="scss">
.content {
  height: 100%;
  .addbtn {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
  }
  .table-title {
    margin-top: 10px;
    padding: 20px 18px 0 18px;
    background: #fff;
  }

}
.row {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  .label {
    width: 50px;
  }
}
.search {
  width: 300px;
}
</style>
