var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{staticClass:"roleauth",attrs:{"title":_vm.title,"visible":_vm.currentVisible,"width":"1000px","close-on-click-modal":false,"close-on-press-escape":false,"showFooter":false},on:{"update:visible":function($event){_vm.currentVisible=$event},"closed":_vm.closed}},[_c('el-row',[_c('div',{staticClass:"authSpan"},[_c('span',[_vm._v("授权角色："+_vm._s(_vm.form.roleName)+" ")]),_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v("授权应用： "+_vm._s(_vm.form.resourceName))])])]),_c('el-container',{staticClass:"auth-container"},[_c('div',{staticClass:"left"},[_c('base-form',{staticClass:"org_filter",attrs:{"componentList":_vm.componentLists,"showBtns":false,"formAttrs":{
          model: _vm.searchForm,
          inline: true,
          labelWidth: '70px',
          labelPosition: 'left',
        }}}),_c('div',{staticClass:"tree-container"},[_c('el-tree',{ref:"leftTree",staticClass:"assign-tree",attrs:{"data":_vm.treeSource,"show-checkbox":"","empty-text":_vm.emptyText,"accordion":"","filter-node-method":_vm.filterNode,"node-key":"resourceKid","default-expanded-keys":['-1'],"check-strictly":"","render-after-expand":false,"highlight-current":"","default-checked-keys":_vm.treeCheckedList,"props":_vm.defaultProps},on:{"node-click":_vm.nodeClick,"check-change":_vm.treeChange,"check":_vm.check},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[(data.method !== undefined)?_c('el-tag',{attrs:{"size":"mini","effect":"dark","type":_vm.type(data.method.toUpperCase())}},[_vm._v(_vm._s(data.method.toUpperCase()))]):_vm._e(),_c('span',[_vm._v(_vm._s(data.path))]),(data.resourceDesc)?_c('el-tooltip',{attrs:{"enterable":false,"content":data.resourceDesc,"placement":"top"}},[_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(data.resourceName))])]):_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(data.resourceName))])],1)}}])})],1)],1),_c('div',{staticClass:"right"},[_c('transition',{attrs:{"name":"el-fade-in-linear"}},[(_vm.permsTitle !== '')?_c('el-main',{staticClass:"authRight"},[_vm._v(" "+_vm._s(("「 " + _vm.permsTitle + "」操作权限"))+" "),_c('el-tree',{ref:"rightTree",attrs:{"data":_vm.operationSource,"show-checkbox":"","default-expand-all":"","node-key":"resourceKid","highlight-current":"","default-checked-keys":_vm.operationCheckedList,"props":_vm.defaultProps},on:{"check-change":_vm.operationChange,"check":_vm.operationCheck},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[(data.resourceDesc)?_c('el-tooltip',{attrs:{"enterable":false,"content":data.resourceDesc,"placement":"top"}},[_c('span',[_vm._v(_vm._s(data.resourceName))])]):_c('span',[_vm._v(_vm._s(data.resourceName))])],1)}}],null,false,1860683597)})],1):_vm._e()],1)],1)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('base-button',{attrs:{"size":"small","type":"primary","label":"保 存"},on:{"click":_vm.save}}),_c('base-button',{attrs:{"size":"small","type":"default","label":"关 闭"},on:{"click":_vm.close}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }