import { getDict } from '@/filters/fromDict'
// 查询form
export const dataForm = () => {
  return [
    {
      label: '应用:',
      prop: 'search',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入应用名,应用编码'
      }
    }

  ]
}
// 表格form
export const dataTable = (context) => {
  return [
    {
      label: '应用名称',
      prop: 'resourceName'
    },
    {
      label: '应用编码',
      prop: 'resourceCode'
    },
    {
      label: '应用类型',
      prop: 'resourceType',
      formatter: row => getDict('APP_TYPE', row.resourceType)
    },
    {
      label: '应用描述',
      prop: 'resourceDesc'
    },
    {
      label: '授权状态',
      prop: 'authStatus'
    },

    {
      label: '操作',
      prop: 'action'
    }
  ]
}
