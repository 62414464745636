import BaseSelect from '@/components/common/base-select/base-select.vue'

/**
 * 筛选框的配置项
 */
const span = 12
export const buildFilterForm = (context) => {
  return [
    {
      label: '资源类型',
      prop: 'resouceType',
      attrs: {
        options: context.dictOptions,
        selectedField: ['dictId', 'dictName']
      },
      on: {
        change: context.selectChanged
      },
      isHidden: context.form.appType !== '1',
      tag: BaseSelect,
      span
    },
    {
      label: '关键字',
      prop: 'filterText',
      attrs: {
        placeholder: '请输入搜索内容',
        maxlength: 20
      },
      span
    }
  ]
}
