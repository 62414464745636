<template>
  <base-dialog
    :title="title"
    :visible.sync="currentVisible"
    @closed="closed"
    width="1000px"
    class="roleauth"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :showFooter="false"
  >
    <!-- 头部信息 -->
    <el-row>
      <div class="authSpan">
        <span>授权角色：{{ form.roleName }} </span>
        <span style="margin-left: 20px"
          >授权应用： {{ form.resourceName }}</span
        >
      </div>
    </el-row>

    <el-container class="auth-container">

      <div class="left">
        <base-form
          :componentList="componentLists"
          :showBtns="false"
          class="org_filter"
          :formAttrs="{
            model: searchForm,
            inline: true,
            labelWidth: '70px',
            labelPosition: 'left',
          }"
        >
        </base-form>
        <div class="tree-container">
          <el-tree
            :data="treeSource"
            show-checkbox
            :empty-text="emptyText"
            class="assign-tree"
            accordion
            :filter-node-method="filterNode"
            node-key="resourceKid"
            :default-expanded-keys="['-1']"
            ref="leftTree"
            check-strictly
            :render-after-expand="false"
            highlight-current
            :default-checked-keys="treeCheckedList"
            @node-click="nodeClick"
            @check-change="treeChange"
            @check="check"
            :props="defaultProps"
          >
            <span class="custom-tree-node" slot-scope="{ data }">
              <el-tag
                size="mini"
                effect="dark"
                v-if="data.method !== undefined"
                :type="type(data.method.toUpperCase())"
                >{{ data.method.toUpperCase() }}</el-tag
              >
              <span>{{ data.path }}</span>
              <el-tooltip
                :enterable="false"
                :content="data.resourceDesc"
                placement="top"
                v-if="data.resourceDesc"
              >
                <span style="margin-left: 10px">{{ data.resourceName }}</span>
              </el-tooltip>
              <span v-else style="margin-left: 10px">{{
                data.resourceName
              }}</span>
            </span>
          </el-tree>
        </div>

        <!-- <el-form :inline="true" size="small" label-position="left">
          <el-form-item
            label="资源类型"
            label-width="70px"
          >
            <el-select v-model="form.resouceType" @change="selectChanged">
              <el-option
                v-for="item in dictOptions"
                :key="item.keyId"
                :label="item.dictName"
                :value="item.dictId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键字" label-width="55px">
            <el-input
              placeholder="请输入搜索内容"
              v-model="filterText"
              maxlength="20"
              style="width: 260px"
            ></el-input>
          </el-form-item>
        </el-form> -->
      </div>

      <div class="right">
        <transition name="el-fade-in-linear">
          <el-main v-if="permsTitle !== ''" class="authRight">
            {{ `「 ${permsTitle}」操作权限` }}
            <el-tree
              :data="operationSource"
              show-checkbox
              default-expand-all
              node-key="resourceKid"
              ref="rightTree"
              highlight-current
              @check-change="operationChange"
              @check="operationCheck"
              :default-checked-keys="operationCheckedList"
              :props="defaultProps"
            >
              <span class="custom-tree-node" slot-scope="{ data }">
                <el-tooltip
                  :enterable="false"
                  :content="data.resourceDesc"
                  placement="top"
                  v-if="data.resourceDesc"
                >
                  <span>{{ data.resourceName }}</span>
                </el-tooltip>
                <span v-else>{{ data.resourceName }}</span>
              </span>
            </el-tree>
          </el-main>
        </transition>
      </div>

    </el-container>
    <span slot="footer" class="dialog-footer">
      <base-button
        size="small"
        type="primary"
        @click="save"
        label="保 存"
      ></base-button>
      <base-button
        size="small"
        type="default"
        @click="close"
        label="关 闭"
      ></base-button>
    </span>
  </base-dialog>
</template>

<script>
// import authcenterApi from '@/api/authcenterApi'
// import sysApi from '@/api/sysApi'
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import { getDictLists } from '@/filters/fromDict'
import { getTreeResource, saveAuthBatch } from '@/api/sys/role'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { buildFilterForm } from './utils/role-auth'
import BaseForm from '@/components/common/base-form/base-form.vue'
export default {
  components: { baseDialog, BaseButton, BaseForm },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      searchForm: {},
      btnLoading: false,
      operationSaveList: [],
      treeSaveList: [],
      dictOptions: [],
      menuChildrenObject: {},
      permsCode: '',
      permsTitle: '',
      defaultProps: {
        children: 'children',
        label: 'resourceName'
      },
      treeSource: [],
      treeCheckedList: [],
      operationSource: [],
      operationCheckedList: [],
      expandAll: true,
      emptyText: '请先选择资源类型',
      filterText: ''
    }
  },
  methods: {
    operationCheck () {},
    type (method) {
      const obj = {
        GET: '',
        POST: 'success',
        PUT: 'warning',
        DELETE: 'danger'
      }
      return obj[method]
    },
    nodeClick (data, node, tree) {
      const checked = tree.node.checked
      const $this = this
      if (data.resourceType === '3') {
        if (data.resourceCode === $this.permsCode) {
          return
        }
        $this.operationSource = []
        $this.permsTitle = data.resourceName
        $this.permsCode = data.resourceCode
        const params = {
          resourceType: 'OPERATION',
          roleId: this.form.roleKid,
          parentId: data.resourceKid
        }
        getTreeResource(params).then((res) => {
          $this.operationCheckedList = []
          if (res.data && res.data.length > 0) {
            // disabled 属性修改
            res.data = res.data.map((item) => {
              return {
                ...item,
                disabled: false
              }
            })
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i]
              for (let j = 0; j < $this.operationSaveList.length; j++) {
                const item = $this.operationSaveList[j]
                if (element.resourceKid === item.resourceKid) {
                  element.authStatus = item.authStatus
                  break
                }
              }
            }
            const arr = [
              {
                keyId: '-2',
                resourceName: '操作权限',
                resourceDesc: '操作权限',
                resourceKid: '-2',
                children: res.data,
                disabled: false
              }
            ]
            $this.operationSource = arr
            $this.operationSource[0].children.forEach((item) => {
              item.disabled = !checked
              if (item.authStatus === '0') {
                this.operationCheckedList.push(item.resourceKid)
              }
            })
          } else {
            $this.operationSource = res.data
          }
        })
      } else {
        $this.permsTitle = ''
        $this.permsCode = ''
      }
    },
    close () {
      this.currentVisible = false
    },
    operationChange (v1, v2) {
      const $this = this
      if (v1.keyId !== '-2') {
        for (let i = 0; i < this.operationSaveList.length; i++) {
          const operationSaveItem = $this.operationSaveList[i]
          if (operationSaveItem.resourceKid === v1.resourceKid) {
            $this.operationSaveList.splice(i, 1)
            i--
            return
          }
        }
        v1 = Object.assign({}, v1, { authStatus: v2 ? '0' : '1' })
        this.operationSaveList.push(v1)
      }
    },
    save () {
      const $this = this
      let saveList = [...this.operationSaveList, ...this.treeSaveList]
      saveList = saveList.filter((item) => {
        return item.resourceKid !== '-1' || item.resourceKid !== '-2'
      })
      if (saveList.length === 0) {
        $this.currentVisible = false
        return
      }
      $this.btnLoading = true
      saveAuthBatch(saveList)
        .then((res) => {
          $this.success('授权成功')
          $this.currentVisible = false
        })
        .finally(() => {
          $this.btnLoading = false
        })
    },
    closed () {
      this.treeSource = []
      this.operationSource = []
      this.operationCheckedList = []
      this.operationSaveList = []
      this.treeSaveList = []
      this.permsTitle = ''
      this.permsCode = ''
      this.emptyText = '请先选择资源类型'
      this.filterText = ''
    },
    treeChange (v1, v2, v3) {
      const $this = this
      v1 = Object.assign({}, v1, { authStatus: v2 ? '0' : '1' })
      for (let i = 0; i < this.treeSaveList.length; i++) {
        const treeSaveItem = $this.treeSaveList[i]
        if (
          treeSaveItem.resourceKid === v1.resourceKid &&
          treeSaveItem.resourceType === v1.resourceType
        ) {
          $this.treeSaveList.splice(i, 1)
          return
        }
      }
      this.treeSaveList.push(v1)
    },
    filterNode (value, data) {
      if (!value) {
        return true
      } else {
        return (
          data.resourceName.indexOf(value) !== -1 ||
          data.path.indexOf(value) !== -1
        )
      }
    },
    check (v1, v2) {
      this.$nextTick(() => {
        const checked = v2.checkedKeys.some((item) => item === v1.resourceKid)
        // if (checked) {
        this.checkParent(v1, checked)
        // }
        this.checkChildren(v1, checked)
        const childrenCode = []
        const setCode = (data) => {
          data.forEach((it) => {
            if (it.children) {
              setCode(it.children)
            }
            childrenCode.push(it.resourceCode)
          })
        }
        if (v1.children) setCode(v1.children)
        if (
          v1.resourceCode === this.permsCode ||
          childrenCode.indexOf(this.permsCode) > -1
        ) {
          if (this.operationSource.length > 0) {
            this.operationSource[0].disabled = !checked
            this.operationSource[0].children.forEach((it) => {
              it.disabled = !checked
            })
          }
        }
      })
    },
    checkParent (v1, checked) {
      const $this = this
      let parentNode
      if (this.form.resouceType === 'API') {
        parentNode = $this.$refs.leftTree.getNode('-1')
        $this.$refs.leftTree.setChecked(parentNode.data.resourceKid, checked)
      } else {
        if (!v1.parentKid) {
          return
        }
        if (v1.parentKid === '0') {
          parentNode = $this.$refs.leftTree.getNode('-1')
        } else {
          parentNode = $this.$refs.leftTree.getNode(v1.parentKid)
        }
        let currentCheck = checked
        if (checked === false) {
          // 取消操作
          const { childNodes } = parentNode
          if (
            childNodes.length &&
            childNodes.every((it) => it.checked === false)
          ) {
            currentCheck = false
          } else {
            currentCheck = true
          }
        }

        $this.$refs.leftTree.setChecked(
          parentNode.data.resourceKid,
          currentCheck
        )

        $this.checkParent(parentNode.data, checked)
      }
    },
    checkChildren (v1, checked) {
      const $this = this
      if (v1.children && v1.children.length > 0) {
        v1.children.forEach((item) => {
          $this.$refs.leftTree.setChecked(item.resourceKid, checked)
          if (item.children && item.children.length > 0) {
            $this.checkChildren(item, checked)
          }
        })
      }
    },
    getChecked (data) {
      data.forEach((item) => {
        if (item.authStatus === '0') {
          this.treeCheckedList.push(item.resourceKid)
          if (item.children && item.children.length > 0) {
            this.getChecked(item.children)
          }
        }
      })
      // this.$refs.leftTree.setCheckedKeys(this.treeCheckedList)
    },
    selectChanged (val) {
      const $this = this
      this.treeCheckedList = []
      const obj = this.dictOptions.find((item) => {
        return item.dictId === val
      })
      this.treeSource = []
      const params = {
        resourceType: val,
        roleId: this.form.roleKid,
        parentId: this.form.resourceKid
      }
      getTreeResource(params).then((res) => {
        if (res.data && res.data.length > 0) {
          const arr = [
            {
              resourceName: `${obj.dictName}权限`,
              children: res.data,
              resourceDesc: `${obj.dictName}权限`,
              resourceKid: '-1',
              path: ''
            }
          ]
          $this.treeSource = arr
          $this.getChecked(res.data)
          if ($this.treeCheckedList.length > 0) {
            $this.treeCheckedList.push('-1')
          }
          $this.expandAll = false
        } else {
          $this.treeSource = res.data
        }
      })
      this.emptyText = '该资源暂无数据'
    },

    opened () {
      this.dictOptions = getDictLists('RESOURCE_TYPE')
      if (this.form.appType === '0') {
        this.selectChanged('MENU')
      } else {
        this.dictOptions = this.dictOptions.filter((item) => {
          return item.dictId !== 'MENU'
        })
      }
      // sysApi.dictionary
      //   .getTree({ dictTypeCode: 'RESOURCE_TYPE' })
      //   .then((res) => {
      //     this.dictOptions = res.data[0].childDict
      //     if (this.form.appType === '0') {
      //       this.selectChanged('MENU')
      //     } else {
      //       this.dictOptions = this.dictOptions.filter((item) => {
      //         return item.dictId !== 'MENU'
      //       })
      //     }
      //   })
    }
  },
  watch: {
    'searchForm.filterText' (val) {
      this.$refs.leftTree.filter(val)
    },
    currentVisible (val) {
      if (val) {
        this.opened()
      }
    }
  },
  computed: {
    title () {
      return '权限管理'
    },
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    componentLists () {
      return buildFilterForm(this)
    }
  }
}
</script>

<style lang="scss" scoped>
.authSpan {
  margin-top: 10px;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 10px;
}
.authHeader {
  padding: 0 10px;
  min-height: 80px;
}
.auth-container {
  height: 440px;
  box-sizing: border-box;
  display: flex;
  border: 1px solid #D9E5EE;
  .left {
    box-sizing: border-box;
    padding: 10px;
    width: 680px;
    .org_filter{
      height: 50px;
      /deep/ .el-form-item__label {
        display: inherit;
      }
    }
    .tree-container{
      height: calc(100% - 50px);
      overflow: auto;
    }
  }
  .right {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #D9E5EE;
    flex: 1;
  }
}

.roleauth {
  /deep/ .el-dialog {
    margin-top: 12vh !important;
  }
  /deep/ .el-dialog__body {
    height: 500px;
    overflow-x: hidden;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
  }
}
.tree-container {
  height: 400px;
}
</style>
